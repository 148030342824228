var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"practice-box"},[_c('el-dialog',{attrs:{"title":this.$t('message.notice'),"visible":_vm.showLeaveAlert,"width":"30%","close-on-click-modal":false,"center":""},on:{"update:visible":function($event){_vm.showLeaveAlert=$event}}},[_c('div',[_c('div',{domProps:{"innerHTML":_vm._s(
          _vm.$t('Tests.leaveAlert', {
            counts: this.leaveCountText,
            times: this.leaveSeconds,
            second: this.second
          })
        )}})]),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{attrs:{"type":"default","size":"small"},on:{"click":function($event){_vm.showLeaveAlert = false}}},[_vm._v(" "+_vm._s(this.$t("message.continue"))+" ")])],1)]),(_vm.question && _vm.testTime)?[(_vm.page === 1)?[_c('TestingTools',{attrs:{"showCalculator":_vm.showCalculator,"showReference":_vm.showReference,"practiceType":_vm.practiceType},on:{"pageEnd":_vm.nextPage,"returnToHome":_vm.returnToHome}})]:_vm._e(),(_vm.page === 2)?[(_vm.showQuestion)?[_c('QuestionWrapper',{ref:"QuestionWrapper",attrs:{"questions":_vm.questions,"sectionOrder":_vm.sectionOrder,"sectionName":_vm.sectionName,"examineeName":_vm.examineeName,"questionOrder":parseInt(_vm.questionOrder),"questionCount":_vm.questions.length,"examTimeSeconds":_vm.testTime,"isReviewPage":_vm.isReviewPage,"hasTime":_vm.hasTime,"showAnnotate":_vm.sectionName !== 'Math' && _vm.sectionName !== 'Math calculator',"showCalculator":_vm.sectionName === 'Math calculator' ||
              (_vm.isNew && _vm.sectionName === 'Math'),"showReference":_vm.sectionName === 'Math' || _vm.sectionName === 'Math calculator',"formAnnotation":_vm.question &&
            _vm.question.metadata &&
            _vm.question.metadata.annotation &&
            _vm.question.metadata.annotation.annotation
              ? _vm.question.metadata.annotation.annotation
              : null},on:{"onBackQuestion":_vm.onBackQuestion,"onNextQuestion":_vm.onNextQuestion,"onOrderQuestion":_vm.onOrderQuestion,"onSubmitSection":_vm.onSubmitSection,"onTestTimeout":_vm.onTestTimeout,"onExitTest":_vm.onExitTest,"onToReviewPage":_vm.onToReviewPage,"onReviewPage":_vm.onReviewPage,"setPassage":_vm.setPassage}},[_c('div',{staticClass:"test-paper"},[(_vm.isReviewPage)?[_c('Review',{attrs:{"questions":_vm.questions,"sectionOrder":_vm.sectionOrder,"sectionName":_vm.sectionName},on:{"onOrderQuestionFromReview":_vm.onOrderQuestionFromReview}})]:[(
                  _vm.question.question &&
                    _vm.question.question.type === 'single' &&
                    _vm.question.question.passage_id > 0 &&
                    _vm.question.question.passage.content
                )?_c('MultipleChoiceWithPassage',{key:_vm.question.order,attrs:{"passageIntro":_vm.question.question.passage.introduction && !this.isNew
                    ? _vm.question.question.passage.introduction.intro
                    : null,"passageContent":_vm.getPassage,"questionOrder":parseInt(_vm.questionOrder),"content":_vm.question.question.content,"options":_vm.question.question.options,"answer":_vm.answer ? _vm.answer : '',"marked":_vm.marked,"crossOutActive":_vm.crossOutActive,"crossOutArr":_vm.crossOutArr,"showLine":_vm.sectionName === 'Reading'},on:{"onSwitchMarkStatus":_vm.onSwitchMarkStatus,"onChangeAnswer":_vm.onChangeAnswer,"onSwitchCrossOutStatus":_vm.onSwitchCrossOutStatus,"onChangeCrossOut":_vm.onChangeCrossOut}}):(
                  _vm.question.question &&
                    _vm.question.question.type === 'single' &&
                    (_vm.question.question.passage === null ||
                      _vm.question.question.passage.content === null)
                )?_c('MultipleChoice',{attrs:{"questionOrder":parseInt(_vm.questionOrder),"content":_vm.question.question.content,"options":_vm.question.question.options,"answer":_vm.answer ? _vm.answer : '',"marked":_vm.marked,"crossOutActive":_vm.crossOutActive,"crossOutArr":_vm.crossOutArr},on:{"onSwitchMarkStatus":_vm.onSwitchMarkStatus,"onChangeAnswer":_vm.onChangeAnswer,"onSwitchCrossOutStatus":_vm.onSwitchCrossOutStatus,"onChangeCrossOut":_vm.onChangeCrossOut}}):(_vm.question.question.type === 'math')?_c('Math',{attrs:{"questionOrder":parseInt(_vm.questionOrder),"content":_vm.question.question.content,"options":_vm.question.question.options,"answer":_vm.answer ? _vm.answer : '',"marked":_vm.marked},on:{"onSwitchMarkStatus":_vm.onSwitchMarkStatus,"onChangeAnswer":_vm.onChangeAnswer}}):_vm._e()]],2)])]:[_c('BreakScreen',{attrs:{"examineeName":_vm.examineeName,"breakTimeSeconds":_vm.breakTime[`section${_vm.sectionOrder - 1}${_vm.sectionOrder}`].time},on:{"onBreakTimeout":function($event){_vm.showQuestion = true}}})]]:_vm._e(),_c('el-dialog',{attrs:{"title":this.$t('message.notice'),"visible":_vm.showLeaveAlert,"width":"30%","close-on-click-modal":false,"center":""},on:{"update:visible":function($event){_vm.showLeaveAlert=$event}}},[_c('div',[_c('h6',{domProps:{"innerHTML":_vm._s(
            _vm.$t('toefl.leaveAlert', {
              counts: this.leaveCountText,
              times: this.leaveSeconds,
              second: this.second
            })
          )}})]),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{attrs:{"type":"default","size":"small"},on:{"click":function($event){_vm.showLeaveAlert = false}}},[_vm._v(" "+_vm._s(this.$t("message.continue"))+" ")])],1)])]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }